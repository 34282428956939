<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <div class="container">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-6">
                <div class="product-detail">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade active show"
                      id="product-4"
                      role="tabpanel"
                    >
                      <div class="product-img">
                        <img
                          src="../../../../assets/images/product/main/production_equipement/Screen_Printer/SP1-W.jpg"
                          alt="img-4"
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-xl-6">
                <div class="mt-4">
                  <h1 class="font-size-20 mb-3">
                    <strong> Product description: </strong>
                  </h1>
                  <h2>
                  Provides the highest productivity as well as 6 SIGMA level high quality printing.
                  </h2>
                  <br>
                  <ul class="container">
                    <li>
                      <h5>
                        6 sigma level of high quality printing
                      </h5>
                    </li>
                    <li>
                      <h5>
                        Corrects print offset automatically by feeding back defective SPI printing
                      </h5>
                    </li>
                    <li>
                      <h5>
                        Support for Mixed Production
                      </h5>
                    </li>
                    
                  </ul>
                </div>
              </div>
            </div>
            <!-- end row -->
            <br /><br /><br /><br /><br /><br /><br />
            <h1><strong> Specifications </strong></h1>
            <br /><br />
            <div class="row container" style="font-size:18px;">
              <div class="container-fluid">
               
                  <div class="table-responsive">
                    <table class="table table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <th scope="row" style="width: 400px">
                            Align Repeatability
                          </th>
                          <td style="font-size: 12px">±12.5um @ 6σ
</td>
                        </tr>
                        <tr>
                          <th scope="row">Wet Print</th>
                          <td style="font-size: 12px">±25um @ 6σ
</td>
                        </tr>
                        <tr>
                          <th scope="row">Cycle Time</th>
                          <td style="font-size: 12px">
                            7sec (Excl. Printing Time)

                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Board Handling (mm)</th>
                          <td style="font-size: 12px">
                           Max. L510 × W460 (Single Lane) / L330 × W310 (Dual Lane/Option)

                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Stencil (mm)</th>
                          <td style="font-size: 12px">
                            L550 × W650/L650 × W550 ~ L736 × W736
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                
                <br><br>
                <h1><strong>Features</strong></h1>
                <br><br>

                <div class="row">
                  <div class="col-xl-6">
                    <div class="product-detail">
                      <div class="tab-content" id="v-pills-tabContent">
                        <div
                          class="tab-pane fade active show"
                          id="product-4"
                          role="tabpanel"
                        >
                          <div class="product-img">
                            <img
                              src="../../../../assets/images/product/main/production_equipement/Screen_Printer/sp1_w feature/Flexible Production.jpg"
                              alt="img-4"
                              class="img-fluid mx-auto d-block"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end product img -->
                  </div>
                  <div class="col-xl-6">
                    <div class="mt-4">
                      <h1 class="font-size-20 mb-3" style="color: #f60">
                        <strong> 

Flexible Production </strong>
                      </h1>
                      <h3><strong> Increases the Availability to PCB</strong></h3>
                      <p>
                       Possible to use PCBs of Max. L510mm x W460mm with a single lane.
                      </p>
                    </div>
                  </div>
                </div>
                <!-- end row -->
                <div class="row">
                  <div class="col-xl-6">
                    <div class="product-detail">
                      <div class="tab-content" id="v-pills-tabContent">
                        <div
                          class="tab-pane fade active show"
                          id="product-4"
                          role="tabpanel"
                        >
                          <div class="product-img">
                            <img
                              src="../../../../assets/images/product/main/production_equipement/Screen_Printer/sp1_w feature/hight productivity.jpg"
                              alt="img-4"
                              class="img-fluid mx-auto d-block"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end product img -->
                  </div>
                  <div class="col-xl-6">
                    <div class="mt-4">
                      <h1 class="font-size-20 mb-3" style="color: #f60">
                        <strong> High Productivity </strong>
                      </h1>
                      <h3><strong> Support for Mixed Production</strong></h3>
                      <p>
                        Allows high speed mixed production by connecting two printers in series when applying the dual lane conveyor (bypass).
                      </p>
                    </div>
                  </div>
                </div>
                <!-- end row -->
                <div class="row">
                  <div class="col-xl-6">
                    <div class="product-detail">
                      <div class="tab-content" id="v-pills-tabContent">
                        <div
                          class="tab-pane fade active show"
                          id="product-4"
                          role="tabpanel"
                        >
                          <div class="product-img">
                            <img
                              src="../../../../assets/images/product/main/production_equipement/Screen_Printer/sp1_w feature/convience.jpg"
                              alt="img-4"
                              class="img-fluid mx-auto d-block"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end product img -->
                  </div>
                  <div class="col-xl-6">
                    <div class="mt-4">
                      <h1 class="font-size-20 mb-3" style="color: #f60">
                        <strong> 
Convenience</strong>
                      </h1>
                      <h3><strong> Stage Auto Leveling</strong></h3>
                      <p>
Sets the printing height automatically using the SQG head height measurement sensor                      </p>
                    </div>
                  </div>
                </div>
                <!-- end row -->
                <div class="row">
                  <div class="col-xl-6">
                    <div class="product-detail">
                      <div class="tab-content" id="v-pills-tabContent">
                        <div
                          class="tab-pane fade active show"
                          id="product-4"
                          role="tabpanel"
                        >
                          <div class="product-img">
                            <img
                              src="../../../../assets/images/product/main/production_equipement/Screen_Printer/sp1_w feature/Easy Operation.jpg"
                              alt="img-4"
                              class="img-fluid mx-auto d-block"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- end product img -->
                  </div>
                  <div class="col-xl-6">
                    <div class="mt-4">
                      <h1 class="font-size-20 mb-3" style="color: #f60">
                        <strong> Easy Operation </strong>
                      </h1>
                      <h3>
                        <strong>
                          Increased User Convenience</strong
                        >
                      </h3>
                      <p>
                        Operational convenience is maximized by monitoring a variety of information about production.
                      </p>
                    </div>
                  </div>
                </div>
                <!-- end row -->
              </div>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
};
</script>